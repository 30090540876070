/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import * as React from "react";
import { useState } from "react";
import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import type { HeadFC } from "gatsby";
import type { QueryReturnHead } from "../common/interfaces/types";

// import "@stylesPages/Home.scss";
import "../styles/pages/Home.scss";

// import HeroIcon1 from "@icons/hero-icon1.svg";
// import HeroIcon2 from "@icons/hero-icon2.svg";
// import HeroIcon3 from "@icons/hero-icon3.svg";
// import HeroVideo from "@videos/video.mp4";
// import USD_CAD from "@icons/USD-CAD.png";
// import EUR_USD from "@icons/EUR-USD.png";
// import GBP_USD from "@icons/GBP-USD.png";
// import USD_CHF from "@icons/USD-CHF.png";
// import USD_JPY from "@icons/USD-JPY.png";
// import Arrow from "@icons/arrow_white.svg";
// import Card01 from "@icons/Card01.svg";
// import Card02 from "@icons/Card02.svg";
// import Card03 from "@icons/Card03.svg";
// import Card04 from "@icons/Card04.svg";
// import Protection from "@icons/iconProtec.svg";
// import Privacy from "@icons/iconPrivacy .svg";
// import Tecno from "@icons/tecno.svg";
// import Graph from "@icons/Graph.png";
// import Stars from "@icons/stars.svg";
// import BulletWhite from "@icons/BulletWhite.svg";
// import Close from "@icons/Close.svg";
import HeroIcon1 from "../assets/images/icons/hero-icon1.svg";
import HeroIcon2 from "../assets/images/icons/hero-icon2.svg";
import HeroIcon3 from "../assets/images/icons/hero-icon3.svg";
import HeroVideo from "../assets/videos/video_blue.mp4";
import USD_CAD from "../assets/images/icons/USD-CAD.png";
import EUR_USD from "../assets/images/icons/EUR-USD.png";
import GBP_USD from "../assets/images/icons/GBP-USD.png";
import USD_CHF from "../assets/images/icons/USD-CHF.png";
import USD_JPY from "../assets/images/icons/USD-JPY.png";
import Arrow from "../assets/images/icons/arrow_white.svg";
import Card01 from "../assets/images/icons/Card01.svg";
import Card02 from "../assets/images/icons/Card02.svg";
import Card03 from "../assets/images/icons/Card03.svg";
import Card04 from "../assets/images/icons/Card04.svg";
import Protection from "../assets/images/icons/iconProtec.svg";
import Privacy from "../assets/images/icons/iconPrivacy .svg";
import Tecno from "../assets/images/icons/tecno.svg";
import Graph from "../assets/images/icons/Graph.png";
import Stars from "../assets/images/icons/stars.svg";
import BulletWhite from "../assets/images/icons/BulletWhite.svg";

// import { SEO, Header, Footer } from "@components";
import { SEO, Header, Footer } from "../components";

const IndexPage = () => {
  const { t } = useTranslation(["home", "heros"]);
  const [closeAlert, setCloseAlert] = useState(false);

  return (
    <>
      <Header home />
      <section className="home__hero">
        <video
          autoPlay
          muted
          controls={false}
          loop
          src={HeroVideo}
          className="home__hero-video"
        />
        <div className="home__hero-container">
          <div className="home__hero-container-box">
            <div className="home__hero-content">
              <h1 className="home__hero-title">
                {`${t("title", { ns: "heros" })}`}
              </h1>
              <h4 className="home__hero-subtitle">
                {`${t("subtitle", { ns: "heros" })}`}
              </h4>
              <Link to="/contact" className="home__hero-button">{t("button-start")}</Link>
            </div>
          </div>
          <div className="home__hero-wrap">
            <article className="home__hero-item">
              <img
                src={HeroIcon1}
                alt="estrategias"
                className="home__hero-icon"
              />
              <p className="home__hero-text">
                {`${t("point_1", { ns: "heros" })}`}
              </p>
            </article>
            <article className="home__hero-item">
              <img src={HeroIcon2} alt="consejos" className="home__hero-icon" />
              <p className="home__hero-text">
                {`${t("point_2", { ns: "heros" })}`}
              </p>
            </article>
            <article className="home__hero-item">
              <img
                src={HeroIcon3}
                alt="plataforma"
                className="home__hero-icon"
              />
              <p className="home__hero-text">
                {`${t("point_3", { ns: "heros" })}`}
              </p>
            </article>
          </div>
        </div>
      </section>
      {/* <section className="home__section-statistics">
        <div className="home__section home__section--statistics">
          <div className="home__box-statistics">
            <img src={GBP_USD} alt="" className="home__country-img" />
            <p className="home__currency">GBP/USD</p>
            <div className="home__data-currency home__data-currency--down">
              <img src={Arrow} alt="" className="home__currency-arrow home__currency-arrow--down " />
              <p className="home__currency-text">1.3191</p>
              <p className="home__currency-text home__currency-text--light">(-1.07%)</p>
            </div>
          </div>
          <div className="home__box-statistics">
            <img src={EUR_USD} alt="" className="home__country-img" />
            <p className="home__currency">EUR/USD</p>
            <div className="home__data-currency home__data-currency--down">
              <img src={Arrow} alt="" className="home__currency-arrow home__currency-arrow--down " />
              <p className="home__currency-text">1.1159</p>
              <p className="home__currency-text home__currency-text--light">(-0.11%)</p>
            </div>
          </div>
          <div className="home__box-statistics">
            <img src={USD_JPY} alt="" className="home__country-img" />
            <p className="home__currency">USD/JPY</p>
            <div className="home__data-currency home__data-currency--up">
              <img src={Arrow} alt="" className="home__currency-arrow home__currency-arrow--up" />
              <p className="home__currency-text">109.59</p>
              <p className="home__currency-text home__currency-text--light">(+0.05%)</p>
            </div>
          </div>
          <div className="home__box-statistics">
            <img src={USD_CAD} alt="" className="home__country-img" />
            <p className="home__currency">GBP/CAD</p>
            <div className="home__data-currency home__data-currency--up">
              <img src={Arrow} alt="" className="home__currency-arrow home__currency-arrow--up" />
              <p className="home__currency-text">1.3172</p>
              <p className="home__currency-text home__currency-text--light">(+0.18%)</p>
            </div>
          </div>
          <div className="home__box-statistics">
            <img src={USD_CHF} alt="" className="home__country-img" />
            <p className="home__currency">USD/CHF</p>
            <div className="home__data-currency home__data-currency--up">
              <img src={Arrow} alt="" className="home__currency-arrow home__currency-arrow--up" />
              <p className="home__currency-text">0.9776</p>
              <p className="home__currency-text home__currency-text--light">(-0.06%)</p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="home__section home__section--intro  home__column">
        <h3 className="home__title">{t("title-intro")}</h3>
        <div className="home__intro-container">
          <div className="home__intro-texts">
            <p className="home__text-second home__text-second--intro">
              {t("intro-text-left-1")}
            </p>
            <p className="home__text-second home__text-second--bold">{t("intro-text-left-2")}</p>
          </div>
          <div className="home__intro-cards">
            <div className="home__intro-card">
              <img className="home__intro-card-img" src={Card01} alt="" />
              <p className="home__text home__text--intro">{t("intro-card-1")}</p>
            </div>
            <div className="home__intro-card">
              <img className="home__intro-card-img" src={Card03} alt="" />
              <p className="home__text home__text--intro">{t("intro-card-3")}</p>
            </div>
            <div className="home__intro-card">
              <img className="home__intro-card-img" src={Card02} alt="" />
              <p className="home__text home__text--intro">{t("intro-card-2")}</p>
            </div>
            <div className="home__intro-card">
              <img className="home__intro-card-img" src={Card04} alt="" />
              <p className="home__text home__text--intro">{t("intro-card-4")}</p>
            </div>
            <p className="home__text-intro-down">
              {t("text-intro-down")}
            </p>
          </div>
        </div>
      </section>
      <section className="home__section home__section--why  home__column">
        <h3 className="home__title home__title--why">{t("why-title")}</h3>
        <p className="home__text-second home__text-second--subtitle-why ">
          {t("why-subtitle")}
        </p>
        <div className="home__why-container">
          <div className="home__why-card">
            <img src={Protection} alt="" className="home__why-card-img" />
            <div className="home__why-card-text-content">
              <p className="home__text-second home__text-second--why">{t("why-title-icon-1")}</p>
              <p className="home__text home__text--why">
                {t("why-text-1")}
              </p>
            </div>
          </div>
          <div className="home__why-card">
            <img src={Privacy} alt="" className="home__why-card-img" />
            <div className="home__why-card-text-content">
              <p className="home__text-second home__text-second--why">{t("why-title-icon-2")}</p>
              <p className="home__text home__text--why">
                {t("why-text-2")}
              </p>
            </div>
          </div>
          <div className="home__why-card">
            <img src={Tecno} alt="" className="home__why-card-img" />
            <div className="home__why-card-text-content">
              <p className="home__text-second home__text-second--why">{t("why-title-icon-3")}</p>
              <p className="home__text home__text--why">
                {t("why-text-3")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="home__account">
        <div className="home__section--account">
          <div className="home__account-content-img">
            <img src={Graph} alt="" className="home__account-img" />
          </div>
          <div className="home__account-content-list">
            <p className="home__title home__title--account">{t("account-title")}</p>
            <p className="home__text-second home__text-second--account">
              {t("account-subtitle")}
            </p>
            <img src={BulletWhite} alt="" className="home__account-list-img" />
            <p className="home__text home__text--account" dangerouslySetInnerHTML={{ __html: t("account-text-1") }}>
            </p>
            <img src={BulletWhite} alt="" className="home__account-list-img" />
            <p className="home__text home__text--account" dangerouslySetInnerHTML={{ __html: t("account-text-2") }}>
            </p>
            <img src={BulletWhite} alt="" className="home__account-list-img" />
            <p className="home__text home__text--account" dangerouslySetInnerHTML={{ __html: t("account-text-3") }}>
            </p>
            <Link to="/contact" className="home__text home__account-button">{t("button-start")}</Link>
          </div>
        </div>
      </section>
      <section className="home__section home__section--clients  home__column">
        <h3 className="home__title home__title--clients">{t("clients-title")}</h3>
        <div className="home__clients-container">
          <div className="home__clients-two-cards">
            <div className="home__clients-card">
              <img src={Stars} alt="" className="home__clients-stars-img" />
              <p className="home__text home__text--clients">
                {t("clients-testimony-1")}
              </p>
              <p className="home__text home__text--clients-autor">{t("clients-autor-1")}</p>
            </div>
            <div className="home__clients-card">
              <img src={Stars} alt="" className="home__clients-stars-img" />
              <p className="home__text home__text--clients">
                {t("clients-testimony-2")}
              </p>
              <p className="home__text home__text--clients-autor">{t("clients-autor-2")}</p>
            </div>
          </div>
          <div className="home__clients-card-one">

            <img src={Stars} alt="" className="home__clients-stars-img" />
            <p className="home__text home__text--card-one">{t("clients-testimony-3")}</p>
            <p className="home__text home__text--clients-autor">{t("clients-autor-3")}</p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default IndexPage;

export const Head: HeadFC<QueryReturnHead> = ({ data: { locales } }) => {
  const about = locales.edges.find(({ node }) => (node.ns === "home"));

  const { "title": titleSeo } = about ? JSON.parse(about.node.data) : {
    "title": ""
  };

  return (
    <SEO title={titleSeo} />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;